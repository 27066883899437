<template>
	<div class="lg:ml-4">
		<InlineNotification Heading="Monthly Artist Invoices">
			<template slot="body">
				<p>
					View and download all past invoices. Download them in PDF format, or export them
					to a CSV file.
				</p>
			</template>
		</InlineNotification>

		<div class="max-w-4xl">
			<Accordian heading="Filter" class="lg:mx-0 mx-4">
				<template #contents>
					<div class="my-4 grid grid-flow-row grid-cols-2 pt-0 mt-2 gap-4 pr-0 form">
						<div>
							<label>Artists</label>
							<TRichSelect
								v-model="selectedArtists"
								:multiple="true"
								class="trichselect"
								:options="artistOptions"
							></TRichSelect>
						</div>
						<div>
							<label>Venues</label>
							<TRichSelect
								v-model="selectedVenues" 
								:multiple="true"
								class="trichselect"
								:options="venueOptions"
							></TRichSelect>
						</div>
						<div class="hidden">
							<label> Status </label>
							<TSelect
								v-model="invoiceStatus"
								class="trichselect"
								:options="[
									{ value: 'all', text: 'All' },
									{ value: 'paid', text: 'Paid' },
									{ value: 'unpaid', text: 'Unpaid' },
								]"
							></TSelect>
						</div>
						<div>
							<label>Raised From</label>
              <TDatePicker
                  v-model="raisedFrom"
                  :userFormat="'M, Y'"
                  :dateFormat="'Y-m'"
                  :disabled-dates="disabledFromDates"
              ></TDatePicker>
						</div>
						<div>
							<label>Raised To</label>
              <TDatePicker
                  v-model="raisedTo"
                  :userFormat="'M, Y'"
                  :dateFormat="'Y-m'"
                  :disabled-dates="disabledToDates"
              ></TDatePicker>
						</div>
						<InputText
							name="invoicenumber"
							v-model="invoiceNumber"
							ref="invoicenumber"
							class="mt-0"
							type="number"
							prefix="GP-"
							label="Invoice number"
							placeholder="00001"
						/>

						<div class="col-span-2 justify-start text-left">
							<a class="inline-block cta bg-indigo-500 mr-4" @click="filter()">Filter</a>
							<a v-if="hasActiveFilters()" class="inline-block cta bg-gray-500" @click="resetFilters()">Reset Filters</a>
						</div>
					</div>
				</template>
			</Accordian>
			<div class="flex justify-end"></div>
		</div>

		<div class="lg:mx-0 mx-4 flex flex-col lg:flex-row">
			<a class="lg:mr-4 inline-block cta bg-indigo-500 mt-4" @click="downloadInvoices(true)">
				Download selected invoices ({{ selectedInvoices.length }})
			</a>
			<a class="inline-block cta bg-gp_pink-default-500 mt-4" @click="downloadInvoices(false)">
				Download all invoices
			</a>
		</div>
		<div>
			<div class="lg:mx-0 mx-4 flex flex-col lg:flex-row">
				<a class="lg:mr-4 inline-block cta bg-indigo-500 mt-4" @click="exportCSV()">
					Export to CSV ({{ selectedInvoices.length }})
				</a>
			</div>
		</div>

		<div  class="lg:mx-0 mx-4 flex flex-col lg:flex-row max-w-5xl justify-between items-center  mt-4">
			<div class="flex flex-row my-4">
				<t-toggle name="archived_invoice" v-model="excludeArchived" @change="loadData" class="mr-4"/>
				<label>Exclude Archived Invoices</label>
			</div>
			<div class="flex items-center">
				<span class="mr-4">Sort by</span>
				<div>
					<TSelect class="trichselect thin max-w-sm" v-model="invoice_sortby" @change="updateSort()"
						:options="[
						{ value: '0', text: 'By gig date (newest to oldest)' },
						{ value: '1', text: 'By gig date (oldest to newest)' },
						{ value: '2', text: 'By sign-off date (newest to oldest)' },
						{ value: '3', text: 'By sign-off date (oldest to newest)' },
						{ value: '4', text: 'By artist (A-Z)' },
						{ value: '5', text: 'By artist (Z-A)' },
						]">
					</TSelect>
				</div>
			</div>
		</div>
		<div class="flex flex-col mt-4" v-if="results && results.length > 0">
			<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
					<div class="max-w-5xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
						<template>
							<div  class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
								<div class="flex flex-1 justify-center sm:hidden">
									<button @click="prevPage()" 
										class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
										Previous
									</button>
									<button @click="nextPage()" 
										class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
										Next
									</button>
								</div>
								<div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
									<div>
										<p class="text-sm text-gray-700">
											Showing
											<span class="font-medium">{{ this.paginate_from }}</span>
											to
											<span class="font-medium">{{ this.paginate_to }}</span>
											of
											<span class="font-medium">{{ this.paginate_total}}</span>
											results
										</p>
									</div>
									<div>
										<nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" 
											aria-label="Pagination"> 
											<button @click="changePage(1)" type="button" 
												class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
												<span class="sr-only">Previous</span>
												<ChevronDoubleLeftIcon class="h-5 w-5" aria-hidden="true" />
											</button>

											<button @click="prevPage()" type="button" 
												class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
												<span class="sr-only">Previous</span>
												<ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
											</button>

											<span v-show="this.paginate_currPage > 1" 
												class="cursor-default	relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
												...
											</span>
											<button type="button" aria-current="page"
												class="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
												{{ this.paginate_currPage }}
											</button>
											<button v-show="this.paginate_currPage < this.paginate_lastPage - 3" 
												@click="changePage(paginate_currPage + 1)" 
												class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
												{{ this.paginate_currPage + 1 }}
											</button>
											<button v-show="this.paginate_currPage < this.paginate_lastPage - 4" 
												@click="changePage(paginate_currPage + 2)" 
												class="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">
												{{ this.paginate_currPage + 2 }}
											</button>
											<span v-show="this.paginate_currPage <= this.paginate_lastPage - 6" 
												class="cursor-default	relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
												...
											</span>
											<button v-show="this.paginate_currPage < this.paginate_lastPage - 2" 
												@click="changePage(paginate_lastPage - 2)" 
												class="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">
												{{ this.paginate_lastPage - 2}}
											</button>
											<button v-show="this.paginate_currPage < this.paginate_lastPage - 1" 
												@click="changePage(paginate_lastPage - 1)" 
												class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
												{{ this.paginate_lastPage - 1 }}
											</button>
											<button v-show="this.paginate_currPage !== this.paginate_lastPage" 
												@click="changePage(paginate_lastPage)" 
												class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
												{{ this.paginate_lastPage }}
											</button>
											<button @click="nextPage()" type="button" 
												class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
												<span class="sr-only">Next</span>
												<ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
											</button>

											<button @click="changePage(paginate_lastPage)" type="button" 
												class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
												<span class="sr-only">Next</span>
												<ChevronDoubleRightIcon class="h-5 w-5" aria-hidden="true" /> 
											</button>
										</nav>
									</div>
								</div>
							</div>
						</template>
						<table class="min-w-full divide-y divide-gray-200 text-left">
							<thead class="bg-gray-50">
								<tr>
									<th scope="col" class="relative px-6 py-3">
										<input ref="selectall" type="checkbox" @change="selectAll($event.target.checked)" />
									</th>
									<th scope="col" class="relative px-6 py-3">
										<span class="sr-only">Edit</span>
									</th>
									<th scope="col"
										class="px-2 py-3 marker: text-xs font-medium text-gray-500 uppercase tracking-wider">
										Artist
									</th>
									<th scope="col"
										class="px-2 py-3 marker: text-xs font-medium text-gray-500 uppercase tracking-wider">
										Venue
									</th>
									<th scope="col"
										class="px-2 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
										Status
									</th>
								</tr>
							</thead>
							<tbody class="bg-white divide-y divide-gray-200">
								<tr class="relative" 
									v-for="(bid, index) in invoices"
									:key="index"
									:row="bid"
								>
									<td class="p-6">
										<input ref="check" v-bind:id="bid.id" type="checkbox"
											@change="toggleOffer($event.target.checked, bid)"
										/>
									</td>
									<td class="p-6 w-1/6">
										<a @click="downloadInvoice(bid.artist.id, bid.month)" class="block mt-2 text-gp_pink-default-500">
											Download
										</a>
										<a @click="archiveInvoiceLocal(bid)" class="block mt-2 text-gp_pink-default-500"
											v-if="bid.invoice && bid.invoice.archived_at == null">
											Click to archive
										</a>
									</td>
									<td class="text-left mx-2 px-2 py-6 justify-center h-full items-center">
										<span v-if="bid.artist" class="block">
											{{ bid.artist.name }}
											<span v-if="bid.artist" v-show="bid.artist.deleted_at" class="text-xs">[Artist deleted]</span>
										</span>
<!--										<span>£{{ bid.fee }}</span>-->
										<span class="block">{{ formatPrettyDate(bid.month) }}</span>
									</td>
									<td class="text-left mx-2 px-2 py-6 justify-center h-full items-center">
										<span v-if="bid.venue" class="block">
											{{ bid.venue.name }}
											<span v-if="bid.venue" v-show="bid.venue.deleted_at" class="text-xs">[Venue deleted]</span>
										</span>
									</td>
									<td class="pl-2 pr-6 py-6 flex flex-col items-start justify-start gap-2">
										<span>
											<span class="font-bold">Raised</span> 
											{{ bid.invoice_date_raised  }}
										</span>

										<span>
											<span class="font-bold">Sign-off by</span><br/>
											{{ bid.signed_off_by.name ? bid.signed_off_by.name : bid.signed_off_by.email }}
										</span>
									</td>
								</tr>
							</tbody>
						</table>

						<template>
							<div  class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
								<div class="flex flex-1 justify-center sm:hidden">
									<button @click="prevPage()" 
										class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
										Previous
									</button>
									<button @click="nextPage()" 
										class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
										Next
									</button>
								</div>
								<div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
									<div>
										<p class="text-sm text-gray-700">
											Showing
											<span class="font-medium">{{ this.paginate_from }}</span>
											to
											<span class="font-medium">{{ this.paginate_to }}</span>
											of
											<span class="font-medium">{{ this.paginate_total}}</span>
											results
										</p>
									</div>
									<div>
										<nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" 
											aria-label="Pagination"> 
											<button @click="changePage(1)" type="button" 
												class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
												<span class="sr-only">Previous</span>
												<ChevronDoubleLeftIcon class="h-5 w-5" aria-hidden="true" />
											</button>

											<button @click="prevPage()" type="button" 
												class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
												<span class="sr-only">Previous</span>
												<ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
											</button>

											<span v-show="this.paginate_currPage > 1" 
												class="cursor-default	relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
												...
											</span>
											<button type="button" aria-current="page"
												class="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
												{{ this.paginate_currPage }}
											</button>
											<button v-show="this.paginate_currPage < this.paginate_lastPage - 3" 
												@click="changePage(paginate_currPage + 1)" 
												class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
												{{ this.paginate_currPage + 1 }}
											</button>
											<button v-show="this.paginate_currPage < this.paginate_lastPage - 4" 
												@click="changePage(paginate_currPage + 2)" 
												class="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">
												{{ this.paginate_currPage + 2 }}
											</button>
											<span v-show="this.paginate_currPage <= this.paginate_lastPage - 6" 
												class="cursor-default	relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
												...
											</span>
											<button v-show="this.paginate_currPage < this.paginate_lastPage - 2" 
												@click="changePage(paginate_lastPage - 2)" 
												class="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">
												{{ this.paginate_lastPage - 2}}
											</button>
											<button v-show="this.paginate_currPage < this.paginate_lastPage - 1" 
												@click="changePage(paginate_lastPage - 1)" 
												class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
												{{ this.paginate_lastPage - 1 }}
											</button>
											<button v-show="this.paginate_currPage !== this.paginate_lastPage" 
												@click="changePage(paginate_lastPage)" 
												class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
												{{ this.paginate_lastPage }}
											</button>
											<button @click="nextPage()" type="button" 
												class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
												<span class="sr-only">Next</span>
												<ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
											</button>

											<button @click="changePage(paginate_lastPage)" type="button" 
												class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
												<span class="sr-only">Next</span>
												<ChevronDoubleRightIcon class="h-5 w-5" aria-hidden="true" /> 
											</button>
										</nav>
									</div>
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
		<div class="pt-4" v-else-if="invoices && results.length == 0">
			<p>There are no invoices to display, please refine your filters.</p>
		</div>
		<div class="pt-4" v-else>
			<p>Loading your invoices.</p>
		</div>
		<InvoiceGeneration ref="invoice" :invoiceItems="invoiceItems" />

		<div v-show="selectedInvoices.length > 0" class="action-bar justify-between">
			<p class="uppercase text-gp_pink-default-500 font-gp_pink-default-500 font-light px-8">
				Invoices Selected
			</p>
			<div class="flex space-x-2 flex-col space-y-2 lg:flex-row items-end w-full lg:w-1/2"> 
				<button class="cta icon bg-black text-white invisible">Download Invoices</button>
				<button @click="archiveSelected()" class="cta icon bg-indigo-600" v-if="excludeArchived">
					<ArchiveIcon class="mr-2" />Archive Invoices ({{ selectedInvoices.length }})
				</button>
			</div>
		</div>
	</div>
</template>

<style type="text/css" scoped>
	table {
		table-layout: auto;
		width: 1000px;
	}

	table td {
		min-width: min-content;
	}
</style>

<script type="text/javascript">

import {apiComputed, apiMethods, venueMethods} from '@/state/helpers.js'
	import { ArchiveIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon  } from "@vue-hero-icons/outline"
	import fullDate from  '@/utils/format-date-full.js'
	import timeAgo from  '@/utils/format-date-timeago.js'
	import InlineNotification from '../../components/notifications/inline.vue';
	import { TRichSelect } from "vue-tailwind/dist/components";
	import { TSelect } from "vue-tailwind/dist/components";
	import InputText from '../../components/form/InputText.vue';
	import TDatePicker from 'vue-tailwind/dist/t-datepicker';
	import Accordian from '../../components/accordian/Accordian.vue';
	import InvoiceGeneration from '../../components/MonthlyInvoice/wrapper.vue';
	import NProgress from 'nprogress'
	import normalizeDate from '../../utils/normalizeDate.js';
	import { TToggle } from 'vue-tailwind/dist/components';
	import auth from "@/global/auth";

	const SORT_KEY = "venue_invoice_sort";
	const FILTER_STATE_KEY = "monthly_invoice_filters";
	const { DateTime } = require("luxon");

	export default {
		name: "Invoices",
		components:
		{
			InvoiceGeneration,
			InlineNotification,
			InputText,
			TDatePicker,
			TRichSelect,
			TSelect,
			Accordian,
			TToggle,
			ArchiveIcon, 
			ChevronLeftIcon, 
			ChevronRightIcon, 
			ChevronDoubleLeftIcon, 
			ChevronDoubleRightIcon 
		},
		data() {
			return {
				paginate_from: null,
				paginate_to: null,
				toggleArchived: null,
				paginate_total: null,
				paginate_currPage: 1,
				paginate_lastPage: null,
				invoice_sortby: "0",
				excludeArchived: true,
				selectedVenue: null,
				invoiceNumber: '',
				invoiceStatus: 'all',
				raisedFrom: null,
				raisedTo: null,
				selectedInvoices: [],
				invoiceItems: [],
				results: [],
				invoices: null,
				compVenues: null,
				compArtists: null,
				selectedBid:null,
				disabledFromDates: [
					function (date) {
						return date.getDate() !== 1
					}
				],
				disabledToDates: [
					function (date) {
						let d = new Date(date.getYear(), date.getMonth()+1, 0)
						return date.getDate() !== d.getDate()
					}
				],
				selectedArtists: [],
				selectedVenues: [],
				artistOptions: [],
				venueOptions: [],
				isInitialLoad: true,
			}
		},
		watch: {
			toggleArchived(val){
				this.paginate_currPage = 1;
				this.excludeArchived = val;
				this.loadData();
			},
			selectedArtists() {
				this.saveFilterState();
			},
			selectedVenues() {
				this.saveFilterState();
			},
			raisedFrom() {
				this.saveFilterState();
			},
			raisedTo() {
				this.saveFilterState();
			},
			invoiceNumber() {
				this.saveFilterState();
			},
			excludeArchived() {
				this.saveFilterState();
			},
			invoiceStatus() {
				this.saveFilterState();
			}
		},
		computed: {
			...apiComputed,
			getArtistsName(){
				return (gig) => gig && gig.artist ? gig.artist.name : "no artists details"
			},
		},
		mounted(){
			let localSort = localStorage.getItem(SORT_KEY);
			this.invoice_sortby = localSort || "0";
			
			// Load saved filters
			this.loadFilterState();
			
			// Only load data if not already loaded by beforeRouteEnter
			if (this.isInitialLoad) {
				this.loadData();
				this.isInitialLoad = false;
			}
		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
				// Only fetch data if it's the initial load
				if (vm.isInitialLoad) {
					Promise.all([
						vm.$store.dispatch('venue/getAllVenues'),
						vm.$store.dispatch('permissions/getRolePermissions')
					]).then(() => {
						vm.loadData();
						vm.isInitialLoad = false;
					});
				} else {
					next();
				}
			});
		},
		methods: {
			...apiMethods,
      ...venueMethods,
			formatShortDate($date){
				let normalised = normalizeDate($date);
				let datetime = DateTime.fromFormat(normalised, 'yyyy/MM/dd HH:mm');
				return datetime.toFormat('yyyy-MM-dd');
			},
			formatPrettyDate($date){
				let datetime = DateTime.fromFormat($date, 'yyyy-MM');
				const month =  datetime.toFormat('LLL');
				const year =  datetime.toFormat('yyyy');

				return `${month}, ${year}`
			},
			prevPage(){
				if (this.paginate_currPage !== 1) { 
					window.scrollTo(0,0);
					this.paginate_currPage = this.paginate_currPage - 1;
					this.loadData();
				}
			},
			nextPage(){
				if (this.paginate_currPage !== this.paginate_lastPage){ 
					window.scrollTo(0,0);
					this.paginate_currPage = this.paginate_currPage + 1;
					this.loadData();
				}
			},
			changePage(val){ 
				window.scrollTo(0,0);
				this.paginate_currPage = val;
				this.loadData();
			},
			loadData() {
				if (!this.isInitialLoad && !this.compVenues && !this.invoices) {
					return; // Prevent unnecessary reloads
				}
				
				NProgress.start();

				// Use cached data if available
				const promises = [];
				
				// Only fetch venues if we don't have them cached
				if (!this.compVenues) {
					promises.push(
						this.getVenues()
							.then(venues => {
								this.compVenues = venues;
								if (venues && Array.isArray(venues)) {
									this.venueOptions = venues.map(venue => ({
										value: venue.id,
										text: venue.name
									}));
								}
							})
					);
				}
				
				// Always fetch filtered invoices with current filters
				promises.push(this.filter(true));

				Promise.all(promises)
					.catch(error => {
						console.error('Error loading data:', error);
					})
					.finally(() => {
						NProgress.done();
					});
			},
			exportCSV(){
				let payload = this.selectedInvoices.map(invoice => {
					return {
						month: invoice.month,
						artist_id: invoice.artist.id,
						invoice_number: invoice.invoice_number
					}
				})
				this.bookingExportMonthly(payload);
			},
			selectAll($checked){
				this.selectedInvoices = [];
				this.$refs.check.forEach((c) => {
					c.checked = $checked;  
					let $bid = this.invoices.find(bid => bid.id == c.id);
					this.toggleOffer($checked, $bid);
				});
			},
			async archiveInvoiceLocal(bid) {
				NProgress.start();
				try {
					const {data} = await this.archiveInvoice(bid);
					this.updateInvoice(data, bid)
					this.$notify('Invoice archived');
				} catch (err) {
					this.$notify('Error archiving invoice');
				} finally {
					NProgress.done();
				}
			},
			archiveSelected(){
				NProgress.start();
				let invoiceIds = this.selectedInvoices.map(bid => bid.id);

				this.archiveAllSelectedMonthly(invoiceIds).then(() => {
					var clist = document.getElementsByTagName("input");
					for (var i = 0; i < clist.length; ++i) { 
						clist[i].checked = false; 
					}
					this.selectedInvoices = [];
					this.$notify("Invoices successfully archived");
					NProgress.done();
		
					this.filter();
				})
				.catch((error) => {
					this.$notify(error.response.data.message);
				});
			},
			updateInvoice(data, bid) {
				this.results = this.results.map((item) => {
					if (item.id === bid.id) {
						item.invoice.archived_at = data.data.invoice.archived_at;
					}
					return item;
				});
				if (this.excludeArchived) {
					this.results = this.results.filter(b => b.id !== bid.id);
				}
			},
			toggleOffer: function(isOn, $bid){
				if (isOn){
					this.selectedInvoices.push($bid);
				}
				else{
					let removeIndex = this.selectedInvoices.indexOf($bid);
					if (removeIndex !== -1){
						this.selectedInvoices.splice(removeIndex, 1);
					}
				}
			},
			downloadInvoice(artistId, invoiceMonth){
				this.getMonthlyInvoice({artistId: artistId, invoiceMonth: invoiceMonth, isArtist: auth.isArtistView()}).then(response => {
				this.invoiceItems = [response.data];
				this.$refs.invoice.generate();
			})

			},
			downloadInvoices($only_selected) {
				if (this.invoices && this.invoices.length > 0) {
					NProgress.start();
					const paramsInvoices = $only_selected ? this.selectedInvoices : this.invoices;

					// Prepare the invoice parameters
					const params = paramsInvoices.map(item => ({
						artistId: item.artist.id,
						invoiceMonth: item.month
					}));

					// Get the invoice data first
					this.getMonthlyInvoices({
						invoices: params,
						isArtist: auth.isArtistView()
					}).then(response => {
						this.invoiceItems = response.data;
						
						// Wait for next tick to ensure template is updated
						this.$nextTick(() => {
							setTimeout(() => {
								// Use the vue-html2pdf component's generate method
								this.$refs.invoice.generate();
							}, 500);
						});
					}).catch(err => {
						console.error('Error fetching invoices:', err);
						this.$notify('Error fetching invoices');
						NProgress.done();
					});
				}
			},
			filter(skipProgress = false){
				if (!skipProgress) {
					NProgress.start();
				}

				// Save filter state before filtering
				this.saveFilterState();

				let check = this.$refs.check;
				if (check) {
					check.forEach((c) => {
						c.checked = false;
					});
				}

				let selectAll = this.$refs.selectAll;
				if (selectAll) {
					selectAll.checked = false;
				}

				this.selectedInvoices = [];

				let request = {
					filters: {
						date_from: this.raisedFrom,
						date_to: this.raisedTo,
						invoice_number: this.invoiceNumber,
						excludeArchived: this.excludeArchived,
						artists: this.selectedArtists,
						venues: this.selectedVenues,
					},
					invoice_sortby: this.invoice_sortby,
					page: this.paginate_currPage,
					venue_id: this.selectedVenue,
				}

				return this.getFilteredMonthlyInvoices({request: request, useTrashedArtists: true, isArtist: auth.isArtistView()})
					.then((resp) => {
						this.invoices = resp.data;
						this.paginate_to = resp.to;
						this.paginate_from = resp.from;
						this.paginate_total = resp.total;
						this.paginate_lastPage = resp.last_page;
						this.paginate_currPage = resp.current_page; 
						this.getResults();

						// Update artist options from invoice data
						const uniqueArtists = new Map();
						this.invoices.forEach(invoice => {
							if (invoice.artist && !uniqueArtists.has(invoice.artist.id)) {
								uniqueArtists.set(invoice.artist.id, invoice.artist);
							}
						});

						// Convert to array and sort by name
						this.artistOptions = Array.from(uniqueArtists.values())
							.sort((a, b) => a.name.localeCompare(b.name))
							.map(artist => ({
								value: artist.id,
								text: artist.name + (artist.deleted_at ? ' [Artist deleted]' : '')
							}));
					})
					.finally(() => {
						if (!skipProgress) {
							NProgress.done();
						}
					});
			},
			fullDate,
			timeAgo,
			updateSort(){
				localStorage.setItem(SORT_KEY, this.invoice_sortby);
				this.filter();
			},
			getResults() {
				if (this.invoices != null){ 
					this.results = this.invoices;  
					return this.invoices;
				}
			},
			saveFilterState() {
				const filterState = {
					selectedArtists: this.selectedArtists,
					selectedVenues: this.selectedVenues,
					invoiceStatus: this.invoiceStatus,
					raisedFrom: this.raisedFrom,
					raisedTo: this.raisedTo,
					invoiceNumber: this.invoiceNumber,
					excludeArchived: this.excludeArchived,
					paginate_currPage: this.paginate_currPage // Also save current page
				};
				localStorage.setItem(FILTER_STATE_KEY, JSON.stringify(filterState));
			},

			loadFilterState() {
				const savedState = localStorage.getItem(FILTER_STATE_KEY);
				if (savedState) {
					const filterState = JSON.parse(savedState);
					this.selectedArtists = filterState.selectedArtists || [];
					this.selectedVenues = filterState.selectedVenues || [];
					this.invoiceStatus = filterState.invoiceStatus || 'all';
					this.raisedFrom = filterState.raisedFrom || null;
					this.raisedTo = filterState.raisedTo || null;
					this.invoiceNumber = filterState.invoiceNumber || '';
					this.excludeArchived = filterState.excludeArchived !== undefined ? filterState.excludeArchived : true;
					this.paginate_currPage = filterState.paginate_currPage || 1; // Restore current page
					return true;
				}
				return false;
			},

			clearFilterState() {
				localStorage.removeItem(FILTER_STATE_KEY);
				this.selectedArtists = [];
				this.selectedVenues = [];
				this.invoiceStatus = 'all';
				this.raisedFrom = null;
				this.raisedTo = null;
				this.invoiceNumber = '';
				this.excludeArchived = true;
			},

			hasActiveFilters() {
				return this.selectedArtists.length > 0 ||
					this.selectedVenues.length > 0 ||
					this.invoiceStatus !== 'all' ||
					this.raisedFrom !== null ||
					this.raisedTo !== null ||
					this.invoiceNumber !== '' ||
					!this.excludeArchived;
			},

			resetFilters() {
				this.clearFilterState();
				this.filter();
			}
		},
		beforeDestroy() {
			// Save filter state when component is destroyed
			this.saveFilterState();
		},
	};
</script>