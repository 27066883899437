<template>
	<VueHtml2pdf 
		:show-layout="false"
		:float-layout="true"
		:enable-download="enable_download"
		:preview-modal="preview_modal"
		:paginate-elements-by-height="1100"
		filename="monthly_invoices"
		:pdf-quality="2"
		:manual-pagination="true"
		pdf-format="a4"
		pdf-orientation="portrait"
		pdf-content-width="800px"
		:margin="10"
		@progress="onProgress($event)"
		@hasStartedGeneration="hasStartedGeneration()"
		@hasGenerated="hasGenerated($event)"
		ref="html2Pdf"
	>
		<section slot="pdf-content" id="pdf_content">
			<div v-for="(page, index) in invoiceItems" :key="index" class="page-break">
				<InvoiceItem :index="index" :invoice="page" />
			</div>
		</section>
	</VueHtml2pdf>
</template>

<style scoped>
.page-break {
	page-break-after: always;
}
</style>

<script>
	import VueHtml2pdf from 'vue-html2pdf';
	import InvoiceItem from './contents.vue'
	import NProgress from 'nprogress'

	export default{
		created() {
			window.addEventListener("resize", this.setDownloadPreviewParams);
		},
		mounted() {
			this.setDownloadPreviewParams();
			this.calculateTotal();
		},
		beforeDestroy() {
			window.removeEventListener("resize", this.setDownloadPreviewParams);
		},
		name: "InvoiceGeneration",
		props:{
			invoiceItems: {
				type: Array,
				required: true
			}
		},
		data(){
			return{
				enable_download: false,
				preview_modal: true,
				invoice_total: 0
			}
		},
		components:{
			InvoiceItem, VueHtml2pdf
		},
		methods:{
			calculateTotal(){
				this.invoice_total = 0;
				if (this.invoiceItems) {
					this.invoiceItems.forEach(item => {
						if (item && item.fee) {
							this.invoice_total += parseFloat(item.fee);
						}
					});
				}
			},
			setDownloadPreviewParams() {
				const isMobile = window.matchMedia("(max-width:768px)").matches;
				// On mobile, enable direct download. On desktop, show preview
				this.enable_download = isMobile;
				this.preview_modal = !isMobile;
			},
			generate(){
				if (this.$refs.html2Pdf) {
					NProgress.start();
					this.$refs.html2Pdf.generatePdf();
				}
			},     
			onProgress(progress){
				this.$emit('progress', progress);
				// If we're showing preview, complete the loading when progress is 100%
				if (this.preview_modal && progress === 100) {
					NProgress.done();
				}
			},
			hasGenerated(response){
				this.$emit('generated', response);
				// Only complete loading on actual PDF generation (download)
				if (!this.preview_modal) {
					NProgress.done();
				}
			},
			hasStartedGeneration(){
				this.$emit('started');
			},
		}
	}

</script>