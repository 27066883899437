import client from "../../utils/client"

export const state = {
  venueRoles: [],
}

export const mutations = {
  SET_VENUE_ROLES(state, { roles, venueId }) {
		const index = state.venueRoles.findIndex(r => r.venueId === venueId)
    index > -1 
			? state.venueRoles[index].roles = roles 
			: state.venueRoles.push({
				venueId: venueId,
				roles
			})
  },
	ADD_VENUE_ROLE(state, { role, venueId }) {
		const index = state.venueRoles.findIndex(r => r.venueId === venueId)
    index > -1 
			? state.venueRoles[index].roles.push(role) 
			: state.venueRoles.push({
				venueId: venueId,
				roles:[ role ]
			})
  },
	UPDATE_VENUE_ROLE(state, { role, venueId }) {
		const index = state.venueRoles.findIndex(r => r.venueId === venueId)
    if(index > -1){
			const roleIndex = state.venueRoles[index].roles.findIndex(r => r.id === role.id)
			state.venueRoles[index].roles[roleIndex] = role;
		}
  },
	DELETE_VENUE_ROLE(state, { role, venueId }) {
		const index = state.venueRoles.findIndex(r => r.venueId === venueId)
    if(index > -1){
			state.venueRoles[index].roles = state.venueRoles[index].roles.filter(r => r.id !== role.id)
		}
  }
}

export const getters = {
	all: state => {
    return state.venueRoles.roles;
  },
	venueRoles: state => venueId => {
		const i = state.venueRoles.findIndex(r => r.venueId === venueId)
    return i > -1 ? state.venueRoles[i].roles : null;
  },

}

export const actions = {
  getVenueRoles({ getters, commit }, venueId) {
		return new Promise((resolve) => {
			// Check if we already have the roles for this venue
			if(getters['venueRoles'](venueId)){
				console.log("returning cached roles for venue", venueId);
				resolve(getters['venueRoles'](venueId))
			} else {
				console.log("fetching roles for venue", venueId);
				client.get(`/venues/${venueId}/roles`)
					.then((response) => {
						commit('SET_VENUE_ROLES', {
							roles: response.data.data, 
							venueId
						});
						resolve(getters['venueRoles'](venueId))
					})
					.catch(err => {
						console.log(err)
					})
			}
		})
  },
	addVenueRole({ commit }, { venueId, role }) {
		return new Promise((resolve) => {
			commit('ADD_VENUE_ROLE', {
				role: role, 
				venueId
			});
			resolve(role)
	
		})
  },
	updateVenueRole({ commit }, { venueId, role }) {
		return new Promise((resolve) => {
			commit('UPDATE_VENUE_ROLE', {
				role: role, 
				venueId
			});
			resolve(role)
	
		})
  },
	deleteVenueRole({ commit }, { venueId, role }) {
		return new Promise((resolve) => {
			commit('DELETE_VENUE_ROLE', {
				role: role, 
				venueId
			});
			resolve(role)
	
		})
  },
	requestVenueRoles({ commit }, venueId) {
		return new Promise((resolve) => {
			// return dispatch('requestVenueRoles', venueId);
			client.get(`/venues/${venueId}/roles`)
				.then((response) => {
					commit('SET_VENUE_ROLES', {
						roles: response.data.data, 
						venueId
					});
					setTimeout(() => {
						resolve(getters['venueRoles'](venueId))
					}, 100);
					
				})
				.catch(err => {
					console.log(err)
				})
		})
  }
} 